body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Lexend";
  src: local("Lexend"), url("./fonts/Lexend-Medium.ttf") format("truetype");
  font-weight: bold;
}
.login-form {
  justify-content: center;
  min-height: 90vh;
}
.button-block {
  width: 100%;
}
.login-background {
  justify-content: center;
  min-height: 30vh;
  padding: 50px;
}
